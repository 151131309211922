import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Header from "../../common/header/Header";
import Footer from '../../common/footer/Footer';
import { Link } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";
import { Element, scroller } from "react-scroll";
import axios from "axios";
import ProjectDetail from "../../uncatogorized/modal/ProjectDetail";
import Profile from "../../common/profile/Profile";
import { API_BASE_URL } from "../../../config/Config";
import { enumToTitle } from "../../reusable/strings/Strings";
import { useKeyPressEvent } from "react-use";


const items = [
    <img src={process.env.PUBLIC_URL + "/images/logo_itats.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />,
    <img src={process.env.PUBLIC_URL + "/images/logo_nexwave.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />,
    <img src={process.env.PUBLIC_URL + "/images/logo_job2go.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />,
    <img src={process.env.PUBLIC_URL + "/images/logo_suzuki.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />,
    <img src={process.env.PUBLIC_URL + "/images/logo_tukang.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />,
    <img src={process.env.PUBLIC_URL + "/images/logo_peto.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />,
    <img src={process.env.PUBLIC_URL + "/images/logo_pdv.png"} className="w-40 max-h-20 object-scale-down pr-3" alt="" />
];

const responsive = {
    0: { items: 2 },
    568: { items: 2 },
    1024: { items: 5 },
};

const testimonialsName = ["Arianti Silvia", "Nanang Fakhrur Rozi"];
const testimonialsTitle = ["Digital Design Consultant at McKinsey & Company", "Lecturer at ITATS"];
const testimonialsImage = ["images/via.jpeg", "images/paknanang.jpeg"];
const testimonialsContent = ["Ari is one of a great developer. In such a young age, his knowledge in his domain expertise is quite impressive. He loves what he does, and has the ability to communicate well about the work, even if it's not your domain. We worked on several projects together, and there was a time when we have to work in parallel to create an app from scratch that took both of us 2 weeks to finish and we succeed. That proved his capability in speed and decision making. I would definitely recommend him to anyone that is looking for a driven developer, he will be a valuable asset to your team.",
    "Ari Faradisa has graduated from ITATS. In his journey as a student, he had developed a 'Bridge to Feeder', a web application that is used to synchronize academic data from 'ITATS Academic System' to 'Feeder PD-DIKTI'. His hard work has helped ITATS to synchronize data easily to DIKTI. He is a hard worker, love a challenge, a quick learner, and adaptive to conditions. He is also believed by one of the lecturers to participate in the research. FYI, he is also proficient in developing mobile applications, especially Android-based apps."];

var featuredImage;
var featuredName;

const Home = () => {
    const [isFeaturedLoading, setIsFeaturedLoading] = useState(true);
    const [isShowcaseLoading, setIsShowcaseLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [index, setIndex] = useState(0);
    const [showcases, setShowcases] = useState([]);
    const [showcasex, setShowcasex] = useState();
    const [projectIndex, setProjectIndex] = useState(0);
    const [featuredData, setFeaturedData] = useState();
    const [isMultipleShowcase, setIsMultipleShowcase] = useState(false);

    const currentYear = new Date().getFullYear();
    const experience = currentYear - 2014;

    const showcaseRef = useRef();

    const isDesktop = useMediaQuery({ minWidth: 768 });

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);


    // handle testimonial changes
    useEffect(() => {
        const task = () => {
            setIndex(prevIndex => {
                if (prevIndex === testimonialsName.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            })
        };

        task();

        const intervalId = setInterval(task, 3000);

        return () => clearInterval(intervalId);
    }, []);

    // handle scroll
    useEffect(() => {
        let url = window.location.href.split("#");
        if (url.length > 0) {
            let target = url[url.length - 1].toLowerCase();
            scroller.scrollTo(target, {
                duration: 1500,
                delay: 100,
                smooth: 'easeInOutQuint',
                offset: -120
            });
        }
    }, []);

    // load initial data
    useEffect(() => {
        setIsFeaturedLoading(true);
        axios.get(API_BASE_URL + "/project/featured").then((response) => {
            if (response.data !== null) {
                setFeaturedData(response.data);
                featuredName = response.data.name;
                featuredImage = response.data.img_content;
                setIsFeaturedLoading(false);
            }
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        setIsShowcaseLoading(true);
        axios.get(API_BASE_URL + "/project/showcase").then((response) => {
            if (response.data !== null) {
                setShowcases(response.data);
                setIsShowcaseLoading(false);
            }
        }).catch((err) => {

        });
    }, []);

    useKeyPressEvent("ArrowLeft", () => {
        if (showModal && isMultipleShowcase) {
            onPrevClick();
        }
    });

    useKeyPressEvent("ArrowRight", () => {
        if (showModal && isMultipleShowcase) {
            onNextClick();
        }
    });

    useKeyPressEvent("Escape", () => {
        if (showModal) {
            onCloseClick();
        }
        if (showProfileModal) {
            onProfileCloseClick();
        }
    });

    function onCloseClick() {
        setShowcasex(null);
        setShowModal(false);
        document.body.style.overflow = 'auto';
        showcaseRef.current.style.overflow = 'auto';
    }

    function onProfileCloseClick() {
        setShowProfileModal(false);
        document.body.style.overflow = 'auto';
    }

    function onNextClick() {
        if (projectIndex < showcases.length - 1) {
            setProjectIndex((x) => {
                return x + 1;
            });
            setShowcasex(showcases[projectIndex + 1]);
        }
    }

    function onPrevClick() {
        if (projectIndex > 0) {
            setProjectIndex((x) => {
                return x - 1;
            });
            setShowcasex(showcases[projectIndex - 1]);
        }
    }

    function showFeaturedModal() {
        setShowcasex(featuredData);
        setIsMultipleShowcase(false);
        document.body.style.overflow = 'hidden';
        showcaseRef.current.style.overflow = 'hidden';
    }

    function showProjectModal(index) {
        setIsMultipleShowcase(true);
        setProjectIndex(index);
        setShowcasex(showcases[index]);
        document.body.style.overflow = 'hidden';
        showcaseRef.current.style.overflow = 'hidden';
    }

    useEffect(() => {
        if (showcasex != null) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [showcasex])


    return (
        <>
            <div className="min-h-screen sm:bg-black-bg">
                <div className="md:container md:mx-auto mx-0 sm:mx-6 bg-black-bg">
                    <Header isHome={true} />
                    <section className="bg-white py-8 px-6 sm:px-16">
                        <section id="home">
                            <div className="grid sm:grid-cols-4 sm:grid-flow-row-dense grid-rows-1 gap-3 sm:gap-10">
                                <div className="relative col-span-3">
                                    <img src={process.env.PUBLIC_URL + "/images/bg-home.jpg"} alt="hero" className="w-full h-60 sm:h-full rounded-3xl" />
                                    <div className="absolute bottom-5 sm:bottom-12 left-4 right-4 sm:left-16 sm:right-16 flex flex-col items-center sm:items-start">
                                        <div className="w-10 h-10 sm:w-20 sm:h-20 rounded-full overflow-hidden mb-4">
                                            <img src={process.env.PUBLIC_URL + "/images/profile-mini.jpg"} alt="" className="object-cover h-12 w-12 sm:h-24 sm:w-24 sm:mb-5" />
                                        </div>
                                        <p className="text-white text-[18px] sm:text-2xl sm:mb-3">Hi There!👋🏻 I’m Ari Faradisa</p>
                                        <div className='flex flex-col sm:flex-row sm:gap-40 items-center'>
                                            <p className="text-white text-lg sm:text-[28px] font-semibold flex-1 text-center sm:text-left sm:leading-10">A fullstack developer who can bring your ideas to life</p>
                                            <div>
                                                <Link to="/contact" className="btn-white mt-4 lg:mt-0">Get in Touch</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isFeaturedLoading ? (<div className="flex-row col-span-3 sm:col-span-1">
                                    <div className="mb-6 h-40 sm:h-3/4 w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                    <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                        <div className="flex flex-col flex-auto">
                                            <p className="bg-gray-400 animate-pulse h-5 w-2/4 mb-5"></p>
                                            <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                            <p className="bg-gray-400 animate-pulse h-8 w-2/4"></p>
                                        </div>
                                        <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                    </div>
                                </div>) :
                                    (<a href="/#" onClick={ev => { ev.preventDefault(); showFeaturedModal(); }} className="flex-row col-span-3 sm:col-span-1">
                                        <img src={process.env.PUBLIC_URL + featuredImage} alt="featured" className="h-40 sm:h-3/4 w-full object-fill rounded-lg" />
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full h-1/4">
                                            <div className="flex flex-col flex-auto">
                                                <p className="text-md sm:text-lg font-light sm:mb-2">MY LATEST WORKS</p>
                                                <p className="text-xl sm:text-4xl font-bold sm:leading-2">{featuredName}</p>
                                            </div>
                                            <img src={process.env.PUBLIC_URL + "/images/arrow-circle-right.png"} alt="hero" className="w-10 h-10 content-end" />
                                        </div>
                                    </a>)}
                            </div>
                            <div className="py-16 sm:py-20 z-0">
                                <AliceCarousel autoPlay autoPlayInterval="3000" disableButtonsControls="true" disableDotsControls="true" infinite="true"
                                    items={items} responsive={responsive} paddingRight="50" />
                            </div>
                        </section>
                        <Element id="about">
                            <div>
                                <p className="text-md sm:text-lg font-light sm:mb-2 sm:hidden ">ABOUT ME</p>
                            </div>
                            <div className="grid sm:grid-cols-5 sm:grid-flow-row-dense grid-rows-1 sm:gap-10">
                                <div className="rounded-lg order-first sm:order-last py-2 col-span-5 sm:col-span-2 ">
                                    <img src={process.env.PUBLIC_URL + "/images/profile3.jpg"} alt="profile" className="w-full h-full object-cover rounded-2xl" />
                                </div>
                                <div className="col-span-3">
                                    <p className="text-md sm:text-lg font-light sm:mb-2 hidden sm:block">ABOUT ME</p>
                                    <p className="text-xl sm:text-5xl font-bold sm:leading-2 py-2 sm:py-4">I build things with the software</p>
                                    <p className="text-md sm:text-lg font-light sm:mb-2">Hi, I'm Ari, a Software Developer from Jakarta with a career that began in 2014. With over ten years of experience, I have expertly converted a wide array of UI/UX designs and business processes into practical and impactful products. My passions include system design, optimization, automation, and staying current with the latest innovations in information technology. I specialize in mobile application development, REST API development, and maintaining server infrastructure. Currently, I'm expanding my expertise by diving back into web development using cutting-edge technologies.</p>
                                    <div className="flex justify-center sm:justify-start py-3">
                                        {isDesktop ? (<a
                                            href="/#"
                                            onClick={ev => {
                                                ev.preventDefault();
                                                document.body.style.overflow = 'hidden';
                                                setShowProfileModal(true);
                                            }}
                                            className="btn-default mt-4 lg:mt-0"
                                        >
                                            More About Me!
                                        </a>) : (<Link to="/about" className="btn-default mt-4 lg:mt-0">More About Me!</Link>)}
                                    </div>
                                    <div className="rounded-md border border-grey-box border-solid my-3 divide-y px-4 py-4">
                                        <div className="grid grid-cols-3">
                                            <div className="flex flex-col justify-center">
                                                <p className="text-2xl sm:text-3xl font-bold text-center py-2">{experience}</p>
                                                <p className="text-center text-xs sm:text-lg font-light py-2">Years of Experiences</p>
                                            </div>
                                            <div>
                                                <p className="text-2xl sm:text-3xl font-bold text-center py-2">8+</p>
                                                <p className="text-center text-xs sm:text-lg font-light py-2">Total Companies</p>
                                            </div>
                                            <div>
                                                <p className="text-2xl sm:text-3xl font-bold text-center py-2">10+</p>
                                                <p className="text-center text-xs sm:text-lg font-light py-2">Software Built</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-md sm:text-lg font-light sm:mb-2 text-center pt-4">And, here are some of the programming languages I've been using recently:</p>
                                            <div className="grid grid-cols-3 sm:grid-cols-6 gap-5 sm:gap-10 sm:px-6">
                                                <div className="flex flex-col justify-center">
                                                    <img src={process.env.PUBLIC_URL + "/images/php-big.png"} alt="" className="object-scale-down flex-1" />
                                                    <p className="text-md sm:text-lg font-light sm:mb-2 text-center flex-none">PHP</p>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    <img src={process.env.PUBLIC_URL + "/images/java-big.png"} alt="" className="object-scale-down flex-1" />
                                                    <p className="text-md sm:text-lg font-light sm:mb-2 text-center flex-none">Java</p>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    <img src={process.env.PUBLIC_URL + "/images/kotlin-big.png"} alt="" className="object-scale-down flex-1" />
                                                    <p className="text-md sm:text-lg font-light sm:mb-2 text-center flex-none">Kotlin</p>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    <img src={process.env.PUBLIC_URL + "/images/go-big.png"} alt="" className="object-scale-down flex-1" />
                                                    <p className="text-md sm:text-lg font-light sm:mb-2 text-center flex-none">Go</p>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    <img src={process.env.PUBLIC_URL + "/images/js-big.png"} alt="" className="object-scale-down flex-1" />
                                                    <p className="text-md sm:text-lg font-light sm:mb-2 text-center flex-none">Javascript</p>
                                                </div>
                                                <div className="flex flex-col justify-center">
                                                    <img src={process.env.PUBLIC_URL + "/images/dart-big.png"} alt="" className="object-scale-down flex-1" />
                                                    <p className="text-md sm:text-lg font-light sm:mb-2 text-center flex-none">Dart</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Element>
                        <section id="works">
                            <div className="mt-16">
                                <p className="text-md sm:text-lg font-light sm:mb-2">MY WORKS</p>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-5 sm:gap-40">
                                <div className="col-span-3">
                                    <p className="text-xl sm:text-5xl font-bold sm:leading-2 py-2 sm:py-4">I help your business to make your products come alive.</p>
                                </div>
                                <div className="col-span-2 flex flex-col">
                                    <p className="text-md sm:text-lg font-light sm:mb-2">My experience has helped my clients launch new product in the digital arena throughout the years.
                                        Take a look at some of my greatest work.</p>
                                    <div className="pt-2">
                                        <Link to="/works" className="btn-default mt-4 lg:mt-0">See All My Works</Link>
                                    </div>
                                </div>
                            </div>
                            <div ref={showcaseRef} className="pt-10 flex flex-unwrap flex-row gap-9 overflow-x-auto">
                                {isShowcaseLoading ?
                                    (<><div className="flex flex-col w-full">
                                        <div className="mb-6 h-[250px] sm:h-[460px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                        <div className="hidden sm:flex flex-col w-full">
                                            <div className="mb-6 h-[460px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                            <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                                <div className="flex flex-col flex-auto">
                                                    <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                    <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                                </div>
                                                <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                            </div>
                                        </div></>)
                                    : (showcases.map((showcase, indexs) => (
                                        <a href="/#" key={indexs} onClick={ev => { ev.preventDefault(); showProjectModal(indexs); }} className="flex flex-col flex-shrink-0" >
                                            <img src={process.env.PUBLIC_URL + showcase.img_content} alt="demo1" className="h-44 sm:h-[466px] object-fill" />
                                            <div className="flex flex-row mr-10 flex-1 items-center w-full py-8">
                                                <div className="flex flex-col flex-auto">
                                                    <p className="text-xl sm:text-4xl font-bold sm:leading-2 mb-2">{showcase.name}</p>
                                                    <p className="text-md sm:text-lg font-light sm:mb-2">{enumToTitle(showcase.title).join(", ")}</p>
                                                </div>
                                                <img src={process.env.PUBLIC_URL + "/images/arrow-circle-right.png"} alt="hero" className="w-10 h-10 content-end" />
                                            </div>
                                        </a>
                                    )))}
                            </div>
                        </section>
                        <section id="services">
                            <div className="bg-grey-title rounded-2xl flex flex-col justify-center mt-16 py-16 px-10">
                                <p className="text-md sm:text-lg text-white font-light sm:mb-2 text-center">MY SERVICES</p>
                                <p className="text-xl sm:text-5xl text-white font-bold sm:leading-2 py-2 sm:py-4 text-center sm:px-64">I help your businesses to make their product come to life.</p>
                                <div className="flex flex-col sm:flex-row gap-10 justify-around sm:py-10">
                                    <div className="flex flex-col flex-1 items-center">
                                        <img src={process.env.PUBLIC_URL + "/images/be3.png"} alt="" className="object-scale-down sm:h-96 mb-8" />
                                        <p className="text-md sm:text-2xl text-white font-bold sm:mb-2 text-center flex-none">Backend Developer</p>
                                        <p className="text-md sm:text-lg text-white font-light sm:my-2 text-center">
                                            Enhance your applications and websites by integrating them with databases for richer information. Additionally, leverage machine learning and cutting-edge AI technologies to achieve more precise and accurate outcomes.</p>
                                        <div className="pt-3">
                                            <Link to="/works" className="btn-white mt-4 lg:mt-0">Learn More</Link>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1 items-center">
                                        <img src={process.env.PUBLIC_URL + "/images/fe3.png"} alt="" className="object-scale-down sm:h-96 mb-8" />
                                        <p className="text-md sm:text-2xl text-white font-bold sm:mb-2 text-center flex-none">Frontend Developer</p>
                                        <p className="text-md sm:text-lg text-white font-light sm:my-2 text-center">
                                            Craft an innovative app for your concept and unveil your startup through an engaging website. With responsive design, your site gains global traction, boosting visibility.</p>
                                        <div className="pt-3">
                                            <Link to="/works" className="btn-white mt-4 lg:mt-0">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="testimonials">
                            <div className="mt-16">
                                <p className="text-md sm:text-lg font-light sm:mb-2">TESTIMONIALS</p>
                            </div>
                            <div>
                                <p className="text-xl sm:text-5xl font-bold sm:leading-2 py-5">What they said about my works</p>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-5 sm:gap-10 sm:mt-10 mb-10">
                                <div className="col-span-1 sm:col-span-3 flex flex-col border-grey-box rounded-2xl border-solid border">
                                    <p className="text-[16px] sm:text-[28px] p-6 sm:p-16 text-grey-title h-[28rem] sm:h-[35rem] ">“{testimonialsContent[index]}”</p>
                                    <div className="bg-grey-box flex flex-row p-6 sm:p-16 gap-5 rounded-b-2xl items-center">
                                        <div className="flex flex-col sm:flex-row flex-1 order-2 sm:order-1 gap-5 items-center">
                                            <img src={process.env.PUBLIC_URL + testimonialsImage[index]} alt="" className="flex-none w-12 h-12 sm:w-16 sm:h-16 rounded-full" />
                                            <div className="flex flex-col flex-1 items-center sm:items-start">
                                                <TextTransition springConfig={presets.wobbly} className="text-sm sm:text-2xl text-grey-title font-bold text-center sm:text-left">{testimonialsName[index]} </TextTransition>
                                                <p className="text-xs sm:text-2xl text-grey-title font-light text-center sm:text-left">{testimonialsTitle[index]}</p>
                                            </div>
                                        </div>
                                        <a href="/#" className="sm:flex-none order-1 sm:order-2" onClick={ev => {
                                            ev.preventDefault(); setIndex(prevIndex => {
                                                if (prevIndex === testimonialsName.length - 1) {
                                                    return 0;
                                                }
                                                return prevIndex + 1;
                                            })
                                        }}>
                                            <img src={process.env.PUBLIC_URL + "/images/arrow-circle-left.png"} alt="" className="w-9 h-9 sm:w-14 sm:h-14" />
                                        </a>
                                        <a href="/#" className="sm:flex-none order-3" onClick={ev => {
                                            ev.preventDefault(); setIndex(prevIndex => {
                                                if (prevIndex === 0) {
                                                    return testimonialsName.length - 1;
                                                }
                                                return prevIndex - 1;
                                            })
                                        }}>
                                            <img src={process.env.PUBLIC_URL + "/images/arrow-circle-right.png"} alt="" className="w-9 h-9 sm:w-14 sm:h-14 sm:object-scale-down" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-span-1 sm:col-span-1 bg-blue-theme rounded-2xl flex flex-col justify-center items-center p-6 sm:p-14">
                                    <img src={process.env.PUBLIC_URL + "/images/briefcase.png"} alt="" className="pt-5 w-16" />
                                    <p className="text-white font-bold sm:font-normal text-lg sm:text-4xl text-center py-4 sm:py-8">Interested in building your idea with me?</p>
                                    <Link to="/contact" className="w-full btn-white text-center mt-4 lg:mt-0">Get In Touch</Link>
                                </div>
                            </div>
                        </section>
                    </section>
                    {showModal ? <>
                        <ProjectDetail onCloseClick={onCloseClick} onNextClick={onNextClick} onPrevClick={onPrevClick} showcase={showcasex} isMultipleShowcase={isMultipleShowcase} />
                    </> : null}
                    {showProfileModal ? (
                        <>
                            <div
                                className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none overscroll-contain "
                            >
                                <div className="relative w-auto mx-auto max-w-5xl">
                                    {/*content*/}
                                    <div className="border-0 rounded-lg shadow-lg flex flex-col w-full max-h-screen min-h-full outline-none focus:outline-none">
                                        {/*header*/}
                                        <div className="sticky flex items-end p-5 border-b border-solid border-slate-200 rounded-t-lg bg-white mt-10">
                                            <p className="flex-1 text-right mr-2 text-grey-text">Close</p>
                                            <button
                                                className="bg-transparent text-black text-xl font-semibold"
                                                onClick={() => onProfileCloseClick()}
                                            >
                                                <span className="bg-transparent border-2 border-black rounded-md text-black h-6 w-6 text-sm block">
                                                    x
                                                </span>
                                            </button>
                                        </div>
                                        <div className="bg-white sm:px-16">
                                            <Profile />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Home