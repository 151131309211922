import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    return (
        <>
        <div className="min-h-screen sm:bg-black-bg">
            <div className="md:container md:mx-auto mx-0 sm:mx-6 bg-black-bg">
                <Header/>
                <div className="bg-white flex flex-col items-center p-32">
                    <img src={process.env.PUBLIC_URL + "404.png"} alt="" className="object-scale-down" />
                    <p className="text-4xl font-bold pt-14 pb-8 px-60 text-center">Woops! You’ve moved so fast and got lost...</p>
                    <p className="text-base font-normal text-gray-400 text-center px-52 pb-8">The page you were looking for doesn’t exist. Please check your URL for spellings or capitalizations. If you’re having trouble locating your destination on Ari Faradisa Web, try to visit the <NavLink to="/"><span className="text-blue-theme font-bold">Ari Faradisa Homepage</span></NavLink>.</p>
                    <a
                        href="/#"
                        onClick={ev => { ev.preventDefault(); navigate(-1) }} 
                        className="btn-default mt-4 lg:mt-0"
                    >
                    <div className="flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                        <p>Go Back</p>
                    </div>

                    </a>
                </div>
                <Footer/>
            </div>
        </div>
        </>
    )
}

export default NotFound