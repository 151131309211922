import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import axios from "axios";
import {API_BASE_URL} from "../../../config/Config";
import Portfolio from "../../common/portfolio/Portfolio";

const WorkDetail = () => {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showcasex, setShowcasex] = useState(null);
    const [name, setName] = useState("");

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    useEffect(() => {
        setIsLoading(true);
        axios.get(API_BASE_URL + "/project/"+slug).then((res) => {
            setIsLoading(false);
            setShowcasex(res.data);
            setName(res.data.name);
        }).catch((err) => {
            setIsLoading(false);
            setShowcasex(null);
            setName("");
        });
    }, [slug])

    return (
        <>
            <div className="min-h-screen sm:bg-black-bg">
                <div className="md:container md:mx-auto mx-0 sm:mx-6 bg-black-bg">
                    <Header hasBreadcrumb={true} paths={["My Works",name]} />
                    {isLoading
                            ? (<div className="flex flex-col pt-4">
                                <div className="h-[149px] sm:h-[198px] object-cover bg-gray-400 animate-pulse"></div>
                                <p className="mt-6 sm:pt-10 h-20 w-[500px] bg-gray-400 animate-pulse"></p>
                                <div className="flex flex-col sm:flex-row py-6 sm:py-8 items-start sm:items-center">
                                    <p className="mt-2 sm:pt-10 h-10 w-[300px] bg-gray-400 animate-pulse"></p>
                                    <div className="flex-auto sm:flex-1"></div>
                                    <p className="mt-2 sm:pt-10 h-10 w-[200px] bg-gray-400 animate-pulse"></p>
                                </div>
                                <p className="mt-2 sm:pt-10 h-10 w-[300px] bg-gray-400 animate-pulse"></p>
                                <div className="h-[449px] sm:h-[498px] mt-10 object-cover bg-gray-400 animate-pulse"></div>
                            </div>)
                            : <div><Portfolio showcase={showcasex} /></div>}
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default WorkDetail