import React from 'react';

export default function MiscList(props) {
    const { name  } = props;
    return (
        <li className="text-blue-theme ml-4 my-3">
            <div className="text-black">
            {name}
            </div>
      </li>
    );
}