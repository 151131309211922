import { enumToTitle, enumToTechStack, enumToTeamMember } from "../../reusable/strings/Strings";

const Portfolio = (props) => {
    const { showcase } = props;
    const date = new Date(showcase.project_start);

    return (
        <>
            <div className="flex flex-col overflow-y-auto mb-10 bg-white">
                <img src={process.env.PUBLIC_URL + showcase.img_cover} alt="" className="h-[149px] sm:h-full object-cover" />
                <div className="px-6 sm:px-28 divide-y pb-10">
                    <div>
                        <p className="text-4xl sm:text-5xl font-bold pt-6 sm:pt-10">{showcase.name}</p>
                        <div className="flex flex-col sm:flex-row py-6 sm:py-8 items-start sm:items-center">
                            <div className="flex flex-row items-center pb-2">
                                <img src={process.env.PUBLIC_URL + "/images/task.png"} alt="" className="w-4 h-4" />
                                <p className="text-grey-nav-inactive px-3 text-sm">Tags</p>
                                {enumToTitle(showcase.title).map((title, index) => (
                                    <p key={index} className="text-grey-nav-inactive py-1 sm:py-2 px-4 border rounded-3xl border-grey-title mx-2 text-sm">{title}</p>
                                ))}
                            </div>
                            <div className="flex-auto sm:flex-1"></div>
                            <div className="flex flex-row items-center">
                                <img src={process.env.PUBLIC_URL + "/images/calendar.png"} alt="" className="w-4 h-4" />
                                <p className="text-grey-nav-inactive px-3 text-sm">Year</p>
                                <p className="text-grey-nav-inactive py-1 sm:py-2 px-4 border rounded-3xl border-grey-title mx-2 text-sm">{date.getFullYear()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <p className="font-semibold text-xl py-5">Preview</p>
                        <img src={process.env.PUBLIC_URL + showcase.img_content} alt="" className="pb-3" />

                        <p className="font-semibold text-xl py-5">Description</p>
                        <p className="text-grey-nav-inactive text-base pb-3">{showcase.description}</p>

                        <p className="font-semibold text-xl py-5">Features</p>
                        <ul className="list-disc pl-5">
                            {showcase.features.map((feature, index) => (
                                <li key={index} className="text-grey-nav-inactive text-base pb-3">{feature}</li>
                            ))}
                        </ul>

                        <p className="font-semibold text-xl py-5">Tech Stack</p>
                        <div className="flex flex-col items-center py-5 border border-gray-300 rounded-lg pb-3">
                            <p className="text-grey-nav-inactive text-base pb-5">The project is created using:</p>
                            <div className="grid grid-cols-[repeat(auto-fit,_16.666666%)] gap-10 justify-center p-3 w-full">
                                {showcase.tech_stack.map((stack, index) => {
                                    const tech =  enumToTechStack(stack);
                                    return <div key={index} className="flex flex-col items-center">
                                        <img src={process.env.PUBLIC_URL + "/images/"+tech[1]} alt="" className="h-9" />
                                        <p className="text-grey-nav-inactive text-base text-center pt-5">{tech[0]}</p>
                                    </div>
                                })}
                            </div>
                        </div>

                        <p className="font-semibold text-xl py-5">Team Member</p>
                        <p className="text-grey-nav-inactive text-base">Here are some of the team members who worked on this project:</p>
                        <div className="grid grid-cols-[repeat(auto-fit,_16.666666%)] gap-10 justify-center py-5 w-full">
                            {showcase.team_member.map((team, index) => {
                                const member = enumToTeamMember(team.name);
                                return <div key={index} className="flex flex-col items-center w-full">
                                    <img src={process.env.PUBLIC_URL + "/images/" + member[0]} alt="" className="h-20 w-20 object-cover rounded-full" />
                                    <p className="font-medium text-base text-center pt-5">{member[1]}</p>
                                    <p className="text-grey-nav-inactive text-base text-center">{team.title}</p>
                                </div>
                            })}
                        </div>

                        <div className="flex flex-row items-center">
                            <hr className="h-px bg-blue-theme border-0 flex-1" />
                            <p className="bg-white text-blue-theme px-3">Thank You</p>
                            <hr className="h-px bg-blue-theme border-0 flex-1" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Portfolio