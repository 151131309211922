import React from "react";

const Alert = (props) => {
    const {onClick, type, title, message} = props
    var bgColor = <div className="bg-green-400 w-2 rounded-tl-xl rounded-bl-xl h-full"></div>
    var icon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 p-1 text-center text-white bg-green-400 rounded-full">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
    switch (type) {
        case "success":
            bgColor = <div className="bg-green-400 w-2 rounded-tl-xl rounded-bl-xl h-full"></div>
            icon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 p-1 text-center text-white bg-green-400 rounded-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
            break;
        case "failed":
            bgColor = <div className="bg-red-400 w-2 rounded-tl-xl rounded-bl-xl h-full"></div>
            icon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 p-1 text-center text-white bg-red-400 rounded-full">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>          
            break;
        default:
            break;
    }

    return (
        <>
        <div className="flex flex-row rounded-xl shadow-md items-center mb-8">
            {bgColor}
            <div className="px-4 justify-center">
            {icon}
            </div>
            <div className="flex flex-col flex-1 py-3 justify-center">
                <p className="text-lg font-medium">{title}</p>
                <p className="text-sm text-gray-600">{message}</p>
            </div>
            <a
                href="/#"
                onClick={ev => { ev.preventDefault(); onClick(); }} 
                className="p-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </a>
        </div>
        </>
    )
}

export default Alert