export function enumToTitle(text) {
    const textSplit = text.split(", ");
    var result = [];
    for (const i of textSplit) {
        switch (i) {
            case "backend":
                result.push("Backend Developer");
                break;
            case "frontend":
                result.push("Frontend Developer");
                break;
            case "devops":
                result.push("Devops");
                break;
            default:
                break;
        }
    }
    return result;
}

export function enumToTechStack(text) {
    var stackName = "";
    var stackImage = "";
    switch (text) {
        case "go":
            stackImage = "go-big.png";
            stackName = "Go";
            break;
        case "javascript":
            stackImage = "js-big.png";
            stackName = "Javascript";
            break;
        case "java":
            stackImage = "java-big.png";
            stackName = "Java";
            break;
        case "dart":
            stackImage = "dart-big.png";
            stackName = "Dart";
            break;
        case "jquery":
            stackImage = "jquery-big.png";
            stackName = "JQuery";
            break;
        case "codeigniter":
            stackImage = "ci-big.png";
            stackName = "Codeigniter";
            break;
        case "yii2":
            stackImage = "yii-big.png";
            stackName = "Yii 2 Framework";
            break;
        case "php":
            stackImage = "php-big.png";
            stackName = "PHP";
            break;
        case "firebase":
            stackImage = "firebase-big.png";
            stackName = "Firebase";
            break;
        case "amazons3":
            stackImage = "s3-big.png";
            stackName = "Amazon S3";
            break;
        case "flutter":
            stackImage = "flutter-big.png";
            stackName = "Flutter";
            break;
        case "rxjava":
            stackImage = "rxjava-big.png";
            stackName = "RXJava";
            break;
        case "elasticsearch":
            stackImage = "es-big.png";
            stackName = "Elasticsearch";
            break;
        case "rabbitmq":
            stackImage = "rabbit-big.png";
            stackName = "RabbitMQ";
            break;
        case "nginx":
            stackImage = "nginx-big.png";
            stackName = "Nginx";
            break;
        case "docker":
            stackImage = "docker-big.png";
            stackName = "Docker";
            break;
        case "postal":
            stackImage = "postal-big.png";
            stackName = "Postal";
            break;
        case "strongswan":
            stackImage = "ssw-big.png";
            stackName = "Strongswan";
            break;
        case "letsencrypt":
            stackImage = "le-big.png";
            stackName = "Let's Encrypt";
            break;
        case "zerossl":
            stackImage = "zs-big.png";
            stackName = "ZeroSSL";
            break;
        case "bislink":
            stackImage = "bl-big.png";
            stackName = "BISLink";
            break;
        case "nextjs":
            stackImage = "next-big.png";
            stackName = "NextJS";
            break;
        case "postfix":
            stackImage = "postfix-big.png";
            stackName = "Postfix";
            break;
        case "dovecot":
            stackImage = "dovecot-big.png";
            stackName = "Dovecot";
            break;
        case "roundcube":
            stackImage = "round-big.png";
            stackName = "Roundcube";
            break;
        case "angular":
            stackImage = "angular-big.png";
            stackName = "Angular";
            break;
        case "springboot":
            stackImage = "spring-big.png";
            stackName = "Spring Boot";
            break;
        default:
            stackImage = "";
            stackName = "";
            break;
    }
    return [stackName, stackImage];
}

export function enumToTeamMember(text) {
    var teamImage = "";
    var teamName = "";
    switch (text) {
        case "ari":
            teamImage = "profile-mini.jpg";
            teamName = "Ari Faradisa";
            break;
        case "via":
            teamImage = "via.jpg";
            teamName = "Arianti Silvia";
            break;
        case "rezha":
            teamImage = "rezha.png";
            teamName = "M. Rezha";
            break;
        case "firman":
            teamImage = "firman.png";
            teamName = "Firmansyah";
            break;
        case "andry":
            teamImage = "andry.png";
            teamName = "Andry Huzain";
            break;
        case "andias":
            teamImage = "andias.jpg";
            teamName = "Andias Alam";
            break;
        case "vian":
            teamImage = "vian.png";
            teamName = "Oktavian Lintin";
            break;
        case "dipo":
            teamImage = "dipo.png";
            teamName = "Hasan Muhammad Dipohutomo";
            break;
        case "prans":
            teamImage = "prans.png";
            teamName = "Pransisco Simatupang";
            break;
        case "afnan":
            teamImage = "afnan.png";
            teamName = "M. Rikza Afnan";
            break;
        case "eky":
            teamImage = "eky.jpeg";
            teamName = "Rezkytama Putra";
            break;
        case "imam":
            teamImage = "imam.jpeg";
            teamName = "Imam Ruspandi";
            break;
        case "galang":
            teamImage = "galang.jpeg";
            teamName = "Galang Harindito";
            break;
        case "mares":
            teamImage = "mares.jpeg";
            teamName = "Mares Pradana";
            break;
        case "devan":
            teamImage = "devan.jpeg";
            teamName = "Devan Eka Prasetian";
            break;
        case "andy":
            teamImage = "andy.jpeg";
            teamName = "Andy Rachman";
            break;
        case "nanang":
            teamImage = "nanang.jpeg";
            teamName = "Nanang Fakhrur Rozi";
            break;
        case "reza":
            teamImage = "reza.jpeg";
            teamName = "M. Reza Pahlevi";
            break;
        case "yusuf":
            teamImage = "yusuf.jpeg";
            teamName = "Achmad Yusuf";
            break;
        case "nur":
            teamImage = "nur.jpeg";
            teamName = "Nur Wahid";
            break;
        case "latifu":
            teamImage = "latifu.jpg";
            teamName = "M. Latifu";
            break;
        case "rossi":
            teamImage = "rossi.jpeg";
            teamName = "Septiyawan Rosetya W.";
            break;
        case "sumadi":
            teamImage = "sumadi.jpeg";
            teamName = "Achmad Sumadi";
            break;
        case "nandang":
            teamImage = "nandang.jpeg";
            teamName = "Nandang Gita Nugraha";
            break;
        default:
            break;
    }
    return [teamImage, teamName];
}