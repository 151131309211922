import Portfolio from "../../common/portfolio/Portfolio";

const ProjectDetail = (props) => {
    const { onPrevClick, onNextClick, onCloseClick, showcase, isMultipleShowcase } = props;

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none overscroll-contain "
            >
                <div className="relative w-auto mx-2 sm:mx-auto max-w-full sm:max-w-5xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg flex flex-col w-full max-h-screen min-h-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="sticky flex items-end p-5 border-b border-solid border-slate-200 rounded-t-lg bg-white mt-10">
                            {isMultipleShowcase ? (<div className="flex-1 flex flex-row">
                                <a href="/#" className="sm:flex-none order-1 sm:order-2 pr-2" onClick={ev => { ev.preventDefault(); onPrevClick(); }}>
                                    <img src={process.env.PUBLIC_URL + "/images/arrow-circle-left.png"} alt="" className="w-6 h-6 sm:w-6 sm:h-6" />
                                </a>
                                <a href="/#" className="sm:flex-none order-1 sm:order-2" onClick={ev => { ev.preventDefault(); onNextClick(); }}>
                                    <img src={process.env.PUBLIC_URL + "/images/arrow-circle-right.png"} alt="" className="w-6 h-6 sm:w-6 sm:h-6" />
                                </a>
                            </div>):<div className="flex flex-1"></div>}
                            <div className="flex flex-row">
                                <p className="flex-1 text-right mr-2 text-grey-text">Close</p>
                                <button
                                    className="bg-transparent text-black text-xl font-semibold"
                                    onClick={() => onCloseClick(false)}>
                                    <span className="bg-transparent border-2 border-black rounded-md text-black h-6 w-6 text-sm block">
                                        x
                                    </span>
                                </button>
                            </div>
                        </div>
                        {/*body*/}
                        <Portfolio showcase={showcase} />
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default ProjectDetail