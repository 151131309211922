import React from "react";
import Lottie from "lottie-react";
import Loading from "../../../animation/Animation - 1715083706351.json"


const CustomLoading = ({ isOpen }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed bg-gray-300 bg-opacity-80 inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-lg mx-auto my-6">
                {/* Modal content */}
                <div className="relative modal-container">
                    <div className="bg-white rounded-[20px] shadow-lg px-14 py-5 ">
                        <div className="px-6 py-4 flex flex-col items-center">
                            <Lottie animationData={Loading} />
                            <p className="text-lg">Please wait...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomLoading;