import React, { useState, useEffect } from "react";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import ProjectList from "../../uncatogorized/list/ProjectList";
import ProjectDetail from "../../uncatogorized/modal/ProjectDetail";

import { useKeyPressEvent } from "react-use";

import axios from "axios";
import { API_BASE_URL } from "../../../config/Config";

const Works = () => {
    const [showModal, setShowModal] = useState(false)
    const [isFrontendLoading, setIsFrontendLoading] = useState(true);
    const [isBackendLoading, setIsBackendLoading] = useState(true);
    const [isDevopsLoading, setIsDevopsLoading] = useState(true);
    const [frontend, setFrontend] = useState([]);
    const [backend, setBackend] = useState([]);
    const [devops, setDevops] = useState([]);
    const [showcase, setShowcase] = useState([]);
    const [projectIndex, setProjectIndex] = useState(null);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    useEffect(() => {
        setIsFrontendLoading(true);
        axios.get(API_BASE_URL + "/projects?type=frontend").then((response) => {
            if (response.data !== null) {
                setFrontend(response.data);
                setIsFrontendLoading(false);
            }
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        setIsBackendLoading(true);
        axios.get(API_BASE_URL + "/projects?type=backend").then((response) => {
            if (response.data !== null) {
                setBackend(response.data);
                setIsBackendLoading(false);
            }
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        setIsDevopsLoading(true);
        axios.get(API_BASE_URL + "/projects?type=devops").then((response) => {
            if (response.data !== null) {
                setDevops(response.data);
                setIsDevopsLoading(false);
            }
        }).catch((err) => {

        });
    }, []);

    useEffect(() => {
        if (showcase.length > 0) {
            setShowModal(true);

            document.body.style.overflow = 'hidden';
        }
    }, [showcase])


    useKeyPressEvent("ArrowLeft", () => {
        if (showModal) {
            onPrevClick();
        }
    });

    useKeyPressEvent("ArrowRight", () => {
        if (showModal) {
            onNextClick();
        }
    });

    useKeyPressEvent("Escape", () => {
        if (showModal) {
            onCloseClick();
        }
    });


    const onProjectClick = (text, index) => {
        setProjectIndex(index);
        if (text === "frontend") {
            setShowcase(frontend);
        } else if (text === "backend") {
            setShowcase(backend);
        } else {
            setShowcase(devops);
        }
    }

    function onCloseClick() {
        setShowcase([]);
        setShowModal(false);
        document.body.style.overflow = 'auto';
    }

    function onNextClick() {
        if (projectIndex < showcase.length - 1) {
            setProjectIndex((x) => {
                return x + 1;
            });
        }
    }

    function onPrevClick() {
        if (projectIndex > 0) {
            setProjectIndex((x) => {
                return x - 1;
            });
        }
    }

    return (
        <>
            <div className="min-h-screen sm:bg-black-bg">
                <div className="md:container md:mx-auto mx-0 sm:mx-6 bg-black-bg">
                    <Header hasBreadcrumb={true} paths={["My Works"]} />
                    <div className="bg-white px-8 sm:px-16 py-6 sm:py-12 flex flex-col">
                        <p className="text-2xl sm:text-[44px] font-bold pb-2">Here's All My Works</p>
                        <div className="flex flex-col divide-y">
                            <div className="flex flex-col pb-2">
                                <p className="text-xl font-bold pt-8 pb-2">Frontend Developer</p>
                                <p className="text-base text-grey-nav-inactive">Effortlessly convert your conceptualizations into tangible assets by crafting interactive and user-centric applications or websites. Elevate your company's global footprint and establish a distinctive identity across international domains.</p>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-10 pt-4">
                                {isFrontendLoading ? (<>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                </>) : frontend.map((front, index) => (
                                    <div key={index}>
                                        <ProjectList image={front.img_content} projectName={front.name} major={front.major} onClick={() => onProjectClick("frontend", index)} />
                                    </div>
                                ))}
                            </div>
                        </div>



                        <div className="flex flex-col divide-y my-10">
                            <div className="flex flex-col pb-2">
                                <p className="text-xl font-bold pt-8 pb-2">Backend Developer</p>
                                <p className="text-base text-grey-nav-inactive">Efficiently manage your data, enabling in-depth analysis of sales, employee attendance, and company profitability. Harness the power of AI and seamlessly integrate with various third-party applications to enhance the performance of your enterprise or product.</p>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-10 pt-4">
                                {isBackendLoading ? (<>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                </>) : backend.map((back, index) => (
                                    <div key={index}>
                                        <ProjectList image={back.img_content} projectName={back.name} major={back.major} onClick={() => onProjectClick("backend", index)} />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-col divide-y my-10">
                            <div className="flex flex-col pb-2">
                                <p className="text-xl font-bold pt-8 pb-2">Devops</p>
                                <p className="text-base text-grey-nav-inactive">
                                    Infrastructure serves as the fundamental backbone of any system. Equally imperative is the consideration of security, which must be meticulously integrated into all aspects of the infrastructure to ensure robust and resilient operations.</p>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-4 gap-10 pt-4">
                                {isDevopsLoading ? (<>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="mb-6 h-[310px] w-full object-cover rounded-3xl bg-gray-400 animate-pulse"></div>
                                        <div className="flex flex-row mr-10 flex-1 items-center w-full">
                                            <div className="flex flex-col flex-auto">
                                                <p className="bg-gray-400 animate-pulse h-8 w-3/4 mb-1"></p>
                                                <p className="bg-gray-400 animate-pulse h-5 w-2/4"></p>
                                            </div>
                                            <div className="w-10 h-10 content-end bg-gray-400 animate-pulse rounded-full"></div>
                                        </div>
                                    </div>
                                </>) : devops.map((dev, index) => (
                                    <div key={index}>
                                        <ProjectList image={dev.img_content} projectName={dev.name} major={dev.major} onClick={() => onProjectClick("devops", index)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            {showModal ?
                <>
                    <ProjectDetail onCloseClick={onCloseClick} onNextClick={onNextClick} onPrevClick={onPrevClick} showcase={showcase[projectIndex]} isMultipleShowcase={true} />
                </> : null}
        </>
    )
}

export default Works