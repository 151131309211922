import React from "react";
import { Link as LinkR } from "react-router-dom";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="bg-black-bg px-6 sm:px-16 py-8">
          <p className="text-md text-white sm:mb-2 text-center">CONTACT ME</p>
          <p className="text-xl sm:text-5xl text-white font-bold pt-5 text-center sm:px-64">You’ve got this far.</p>
          <p className="text-xl sm:text-5xl text-white font-bold sm:py-4 text-center sm:px-64">Ready to build your idea?</p>
          <div className="flex justify-center pt-5">
            <LinkR to="/contact" className="btn-gradient mt-4 lg:mt-0">Get In Touch</LinkR>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-4 sm:pt-36">
            <div className="sm:flex flex-row items-center justify-start gap-5 hidden">
              <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" />
              <span className="font-semibold text-[16px] tracking-tight text-white">
                Ari Faradisa
              </span>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-center sm:col-span-2 mt-5 sm:mt-0">
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-nav-active sm:mr-8 text-sm text-center"
                offset={-200}
                activeclass="inactive"
                smooth spy to="home">Home</Link>
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-nav-active sm:mr-8 text-sm text-center"
                offset={-150}
                activeclass="inactive"
                smooth spy to="about">About</Link>
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-nav-active sm:mr-8 text-sm text-center"
                offset={-150}
                activeclass="inactive"
                smooth spy to="works">My Works</Link>
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-nav-active sm:mr-8 text-sm text-center"
                offset={-100}
                activeclass="inactive"
                smooth spy to="services">Services</Link>
              <Link
                className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-blue-nav-active sm:mr-8 text-sm text-center"
                offset={-150}
                activeclass="inactive"
                smooth spy to="testimonials">Testimonials</Link>
              {/* <NavLink
                className="block mt-4 lg:inline-block lg:mt-0  text-white hover:text-blue-nav-active text-sm text-center"
                to="/blog"
                exact>Blog</NavLink> */}
            </div>
            <hr className="h-px mt-8 w-full bg-white border-0 items-center justify-center sm:hidden"></hr>
            <div className="flex flex-row justify-center sm:justify-end items-center gap-16 mt-10 sm:mt-0">
              <a href="https://github.com/AriFaradisa" target="_blank" rel="noreferrer">
                <img src={process.env.PUBLIC_URL + "/images/github.png"} alt="" className="object-scale-down" />
              </a>
              <a href="https://www.linkedin.com/in/arifaradisa/" target="_blank" rel="noreferrer">
                <img src={process.env.PUBLIC_URL + "/images/linkedin.png"} alt="" className="object-scale-down" />
              </a>
              <a href="mailto:work.arifaradisa@gmail.com" target="_blank" rel="noreferrer">
                <img src={process.env.PUBLIC_URL + "/images/mail.png"} alt="" className="object-scale-down" />
              </a>
            </div>
          </div>
          <hr className="h-px my-8 w-full bg-white border-0 items-center justify-center hidden sm:block"></hr>
          <div className="mt-10 sm:mt-0">
            <p className="text-sm text-white text-center">Copyright © 2023 Ari Faradisa. All right reserved</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer