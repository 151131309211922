import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/contents/home/Home';
import NotFound from './components/contents/notfound/NotFound';
import Contact from './components/contents/contact/Contact';
import Works from './components/contents/works/Works';
import WorkDetail from './components/contents/workdetail/WorkDetail';
import About from './components/contents/about/About';
import ScrollToTop from './components/uncatogorized/ScrollToTop';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/works" element={<Works />} />
            <Route path="/work/:slug" element={<WorkDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
