import React from 'react';

export default function ExperienceList(props) {
    const { icon, title, companyName, employmentType, startDate, endDate, duration, url  } = props;
    return (
        <div className="flex flex-row items-start my-2">
            <img src={icon} alt="" className='rounded-full w-14 h-14 border-2 border-blue-nav-active' />
            <div className="flex flex-row items-center flex-1">
                <div className="flex flex-col flex-1 px-5">
                    <p className="font-medium text-base">{title}</p>
                    <p className="text-sm font-normal py-1">{companyName} &#x2022; {employmentType}</p>
                    <p className="text-sm font-normal text-gray-500">{startDate} - {endDate} &#x2022; {duration}</p>
                </div>
                <div className="hidden sm:flex sm:flex-row flex-none items-center">
                    <img className="w-5 h-5 mr-2" src={process.env.PUBLIC_URL + "/images/link-square.png"} alt="" />
                    <a target="_blank" rel="noreferrer" className="text-blue-nav-active underline text-sm" href={url}>{url}</a>
                </div>
            </div>
        </div>
    );
}