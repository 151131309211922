export default function ProjectList(props) {
    const { image, projectName, major, onClick } = props;
    return(
        <a
        href="/#"
        onClick={ev => { ev.preventDefault(); onClick(); }}  >
            <div className="flex flex-col">
                <img src={process.env.PUBLIC_URL + image} alt="" className="object-cover" />
                <div className="flex flex-row pt-5 items-center">
                    <div className="flex flex-col flex-1">
                        <p className="text-2xl font-semibold py-1">{projectName}</p>
                        <p className="text-sm">{major}</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + "/images/arrow-circle-right.png"} alt="" className="w-14 h-14" />
                </div>
            </div>
        </a>
    );
    
}