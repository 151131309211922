import React, { useState } from "react";
import { NavLink, Link as LinkR } from "react-router-dom";
import { Link } from "react-scroll";
import { useMediaQuery } from 'react-responsive';

const Header = (props) => {
  const { isHome, hasBreadcrumb, paths } = props;

  const [isOpen, setIsOpen] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 768 });

  function NavItem({ name, to, isHome, section }) {
    if (isHome) {
      return <Link
        className="block mt-4 lg:inline-block lg:mt-0 text-grey-nav-inactive hover:text-blue-nav-active mr-8 cursor-pointer"
        activeclass="active"
        onClick={closeNavbar}
        offset={isDesktop ? -180 : -350}
        smooth spy to={section}>{name}</Link>;
    }
    return <NavLink
      className="block mt-4 lg:inline-block lg:mt-0 text-grey-nav-inactive hover:text-blue-nav-active mr-8"
      activeclass="active"
      to={section === "home" ? to : (to + "#" + section)}>{name}</NavLink>;
  }

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="sticky top-0 divide-y divide-gray-100 z-10">
        <div className="hidden sm:flex items-center justify-between bg-white px-6 sm:px-16 pt-5 pb-5 h-1">
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="text-sm lg:flex-grow">
              <a href="/#" className="text-grey-nav-inactive">
                arifaradisa.com
              </a>
            </div>
          </div>
          <div className="flex items-center">
            <img
              src={process.env.PUBLIC_URL + "/images/en.png"}
              alt="lang"
              className="w-7 h-7"
            />
            <button
              id="dropdownDefaultButton"
              data-dropdown-toggle="dropdown"
              className="text-black-bg bg-white hover:bg-white focus:ring-4 focus:outline-none focus:ring-transparent rounded-lg text-xs px-4 py-2.5 text-center inline-flex items-center"
              type="button"
            >
              English{" "}
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            <div
              id="dropdown"
              className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <li>
                  <a
                    href="/#"
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    English
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <nav className="flex items-center justify-between flex-wrap bg-white px-6 sm:px-16 pb-4 pt-8 sm:pt-4">
          <div className="flex items-center flex-shrink-0 text-white mr-12">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="mr-4"
            />
            <span className="font-semibold text-xl tracking-tight text-grey-title">
              Ari Faradisa
            </span>
          </div>
          <div className="block lg:hidden">
            <button
              onClick={toggleNavbar}
              className="flex items-center px-3 py-2 border rounded text-black-bg border-grey-400 hover:text-black-bg hover:border-white"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className="hidden w-full flex-grow lg:flex lg:items-center lg:w-auto"
          >
            <div className="text-sm lg:flex-grow">
              <NavItem isHome={isHome} name="Home" section="home" to="/"></NavItem>
              <NavItem isHome={isHome} name="About" section="about" to="/"></NavItem>
              <NavItem isHome={isHome} name="My Works" section="works" to="/"></NavItem>
              <NavItem isHome={isHome} name="Services" section="services" to="/"></NavItem>
              <NavItem isHome={isHome} name="Testimonials" section="testimonials" to="/"></NavItem>
            </div>
            <div className="xs:hidden">
              <LinkR to="/contact" className="btn-default mt-4 lg:mt-0">Get in Touch</LinkR>
            </div>
          </div>
          {isOpen && (<div
            className="w-full flex-grow lg:hidden lg:items-center lg:w-auto"
            id="navbar-default"
          >
            <div className="text-sm lg:flex-grow">
              <NavItem
                isHome={isHome} name="Home" section="home" to="/"></NavItem>
              <NavItem
                isHome={isHome} name="About" section="about" to="/"></NavItem>
              <NavItem
                isHome={isHome} name="My Works" section="works" to="/"></NavItem>
              <NavItem
                isHome={isHome} name="Services" section="services" to="/"></NavItem>
              <NavItem
                isHome={isHome} name="Testimonials" section="testimonials" to="/"></NavItem>
            </div>
            <div className="xs:hidden">
              <LinkR to="/contact" className="btn-default mt-4 lg:mt-0">Get in Touch</LinkR>
            </div>
          </div>)}
        </nav>
        {hasBreadcrumb && (<div className=" bg-white px-6 sm:px-16 py-6 sm:py-8 flex flex-col">
          <div className="flex flex-row items-center">
            <img src={process.env.PUBLIC_URL + "/images/home.png"} alt="" className="w-5 h-5 mr-1" />
            <p className="flex-none px-2 text-grey-nav-inactive">Home</p>
            {paths.map((path, index) => (
              <div key={index} className="flex flex-row" >
                <p>/</p>
                <p className={index < (paths.length - 1) ? "px-2 flex-none text-clip text-grey-nav-inactive" : "px-2 truncate"}>{path}</p>
              </div>
            ))}
          </div>
        </div>)}
        <div></div>
      </div>
    </>
  );
};

export default Header;
