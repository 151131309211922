import React from 'react';

export default function ExpertiseList(props) {
    const { icon, name  } = props;
    return (
        <li className='my-3'>
            <div className="flex flex-row items-center">
                <img className='pr-2 w-7 h-5' src={icon} alt="" />
                <p className='text-base font-medium'>{name}</p>
            </div>
        </li>
    );
}