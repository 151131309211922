import ExperienceList from "../../uncatogorized/list/ExperienceList";
import ExpertiseList from "../../uncatogorized/list/ExpertiseList";
import MiscList from "../../uncatogorized/list/MiscList";
import React, { useEffect } from "react";

import { Link } from "react-router-dom";

const Profile = () => {

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    return (
        <>
            <div className="px-6 sm:px-16 py-10 flex-auto divide-y overflow-y-auto mb-10 bg-white rounded-b-lg">
                <div className="mb-6 flex flex-col sm:flex-row items-center">
                    <div className="">
                        <img src={process.env.PUBLIC_URL + "/images/profile-mini.jpg"} alt="" className="w-32 h-32 object-cover rounded-full border-[6px] border-blue-nav-active" />
                    </div>
                    <div className="flex flex-col flex-1 px-5">
                        <p className="text-blue-nav-active font-bold text-xl text-center sm:text-left pt-4 sm:pt-0">Ari Faradisa</p>
                        <p className="text-grey-text font-semibold text-lg text-center sm:text-left">Software Developer</p>
                    </div>
                    <div className="flex flex-row">
                        <div className="pr-5">
                            <a
                                href={process.env.PUBLIC_URL+"AriFaradisa-Resume.pdf"}
                                download="AriFaradisa-Resume" target='_blank' rel="noreferrer"
                                className="btn-default mt-4 lg:mt-0"
                            >
                                Download Resume!
                            </a>
                        </div>
                        <div>
                            <Link to="/contact" className="btn-white mt-4 lg:mt-0">Get In Touch</Link>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <p className="text-xl font-medium">About Me</p>
                    <p className="my-4 text-grey-text text-base leading-relaxed">
                        Hi, I'm Ari, a Software Developer from Jakarta with a career that began in 2014. With over ten years of experience, I have expertly converted a wide array of UI/UX designs and business processes into practical and impactful products. My passions include system design, optimization, automation, and staying current with the latest innovations in information technology. I specialize in mobile application development, REST API development, and maintaining server infrastructure. Currently, I'm expanding my expertise by diving back into web development using cutting-edge technologies.
                    </p>
                </div>
                <div className="py-6">
                    <p className="text-xl font-medium">Expertise</p>
                    <div className="my-4 grid grid-cols-2 sm:grid-cols-3">
                        <div className="flex-1">
                            <p className="text-grey-nav-inactive py-2 text-base font-normal">Programming Language</p>
                            <ul className="list-none">
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/php.png"} name="PHP" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/java.png"} name="Java" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/kotlin.png"} name="Kotlin" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/go.png"} name="Go" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/dart.png"} name="Dart" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/js.png"} name="Javascript" />
                            </ul>
                            <p className="text-grey-nav-inactive py-2 text-base font-normal">Operating System</p>
                            <ul className="list-none">
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/ubuntu.png"} name="Ubuntu" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/windows.png"} name="Windows" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/macos.png"} name="MacOS" />
                            </ul>
                            <p className="text-grey-nav-inactive py-2 text-base font-normal">Version Control</p>
                            <ul className="list-none">
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/gitlab.png"} name="Gitlab" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/bitbucket.png"} name="BitBucket" />
                            </ul>
                            <p className="text-grey-nav-inactive py-2 text-base font-normal">Database</p>
                            <ul className="list-none">
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/mysql.png"} name="MySQL" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/sqlite.png"} name="SQLite" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/oracle.png"} name="Oracle" />
                            </ul>
                        </div>
                        <div className="flex-1">
                            <p className="text-grey-nav-inactive py-2 text-base font-normal">Tools</p>
                            <ul className="list-none">
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/androidstudio.png"} name="Android Studio" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/intelij.png"} name="IntelliJ IDEA" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/postman.png"} name="Postman" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/jira.png"} name="JIRA" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/confluence.png"} name="Confluence" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/staruml.png"} name="Star UML" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/xcode.png"} name="XCode" />
                            </ul>
                            <p className="text-grey-nav-inactive py-2 text-base font-normal">Others</p>
                            <ul className="list-none">
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/docker.png"} name="Docker" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/nginx.png"} name="NGINX" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/grafana.png"} name="Grafana" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/gitworkflow.png"} name="Git Workflow" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/rabbit.png"} name="RabbitMQ" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/java.png"} name="Postal Mail" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/java.png"} name="Postfix" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/elastic.png"} name="Elasticsearch" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/logstash.png"} name="Logstash" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/kibana.png"} name="Kibana" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/googleapi.png"} name="Google APIs" />
                                <ExpertiseList icon={process.env.PUBLIC_URL + "/images/firebase.png"} name="Firebase" />
                            </ul>
                        </div>
                        <div className="flex-1 col-span-2 sm:col-auto grid sm:flex sm:flex-col grid-cols-2 sm:grid-cols-none">
                            <div>
                                <p className="text-grey-nav-inactive py-2 text-base font-normal">Mobile Miscellaneous</p>
                                <ul className="list-disc">
                                    <MiscList name="GetX" />
                                    <MiscList name="BLoC" />
                                    <MiscList name="MVP" />
                                    <MiscList name="MVVM" />
                                </ul>
                            </div>
                            <div>
                                <p className="text-grey-nav-inactive py-2 text-base font-normal">Web Miscellaneous</p>
                                <ul className="list-disc">
                                    <MiscList name="Codeigniter" />
                                    <MiscList name="Yii2" />
                                    <MiscList name="Laravel" />
                                    <MiscList name="NodeJS" />
                                    <MiscList name="ReactJS" />
                                    <MiscList name="JHipster" />
                                </ul>
                            </div>
                            <div>
                                <p className="text-grey-nav-inactive py-2 text-base font-normal">Language</p>
                                <ul className="list-disc">
                                    <MiscList name="Bahasa (Native)" />
                                    <MiscList name="English (Passive)" />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <p className="text-xl font-medium">Education</p>
                    <div className="my-4 flex flex-row items-center">
                        <img src={process.env.PUBLIC_URL + "/images/itats.png"} alt="" className='rounded-full w-14 h-14 border-2 border-blue-nav-active'/>
                        <div className="flex flex-col flex-1 px-5 gap-1">
                            <p className="font-medium text-sm sm:text-base">Institut Teknologi Adhi Tama Surabaya</p>
                            <p className="font-normal text-xs sm:text-sm">Bachelor of Information Technology</p>
                            <p className="text-grey-nav-inactive font-normal text-xs sm:text-sm">2009 - 2016</p>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <p className="text-xl font-medium">Experiences</p>
                    <div className="my-4 flex flex-col">
                        <div className="flex flex-row items-center">
                            <img src={process.env.PUBLIC_URL + "/images/job2go.png"} alt="" className='rounded-full w-14 h-14 border-2 border-blue-nav-active' />
                            <div className="flex flex-col flex-1 px-5">
                                <p className="font-medium text-base">Job2Go</p>
                                <p className="font-normal text-sm">Full Time (Permanent) &#x2022; 3 yrs 7 mos</p>
                            </div>
                            <div className="hidden sm:flex sm:flex-row items-center">
                                <img className="w-5 h-5 mr-2" src={process.env.PUBLIC_URL + "/images/link-square.png"} alt="" />
                                <a target="_blank" rel="noreferrer" className="text-blue-nav-active underline text-sm" href="https://www.job2go.net">https://www.job2go.net</a>
                            </div>
                        </div>
                        <ul className="relative border-l border-blue-nav-active ml-6 mt-4 mb-3">
                            <li className="mb-4 ml-12">
                                <div className="absolute w-3 h-3 bg-blue-nav-active rounded-full mt-1.5 -left-1.5 border border-blue-nav-active"></div>
                                <time className="mb-1 text-base font-medium">Fullstack Developer</time>
                                <p className="mb-4 text-sm font-normal text-gray-500">Mar 2023 - Apr 2024 &#x2022; 1 yr 1 mo</p>
                            </li>
                            <li className="mb-4 ml-12">
                                <div className="absolute w-3 h-3 bg-blue-nav-active rounded-full mt-1.5 -left-1.5 border border-blue-nav-active"></div>
                                <time className="mb-1 text-base font-medium">Engineering Manager</time>
                                <p className="mb-4 text-sm font-normal text-gray-500">May 2021 - Mar 2023 &#x2022; 11 mos</p>
                            </li>
                            <li className="mb-4 ml-12">
                                <div className="absolute w-3 h-3 bg-blue-nav-active rounded-full mt-1.5 -left-1.5 border border-blue-nav-active"></div>
                                <time className="mb-1 text-base font-medium">Technical Product Manager</time>
                                <p className="mb-4 text-sm font-normal text-gray-500">Sept 2020 - May 2021 &#x2022; 1 yr 9 mos</p>
                            </li>
                            <li className="ml-12">
                                <div className="absolute w-3 h-3 bg-blue-nav-active rounded-full mt-1.5 -left-1.5 border border-blue-nav-active"></div>
                                <time className="mb-1 text-base font-medium">Android Developer</time>
                                <p className="text-sm font-normal text-gray-500">Sept 2019 - Sep 2020 &#x2022; 1yr 1 mo</p>
                            </li>
                        </ul>
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/sonic.jpeg"}
                            title="Senior Software Developer"
                            companyName="PT. Sonic Solusi Digital"
                            employmentType="Freelance"
                            startDate="Oct 2023"
                            endDate="Mar 2024"
                            duration="4 mos"
                            url="https://sonicdigital.id/"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/gmum.png"}
                            title="Android & Backend Developer"
                            companyName="PT. Gama Multi Usaha Mandiri"
                            employmentType="Freelance"
                            startDate="Mar 2019"
                            endDate="May 2019"
                            duration="3 mos"
                            url="https://www.gamamulti.com"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/cmb.png"}
                            title="Android & Backend Developer"
                            companyName="PT. Cakrawala Mitra Bersama"
                            employmentType="Freelance"
                            startDate="Feb 2019"
                            endDate="Mar 2019"
                            duration="1 mo"
                            url="https://www.cakrawalamitrabersama.co.id"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/nexwave.png"}
                            title="Android Developer"
                            companyName="PT. Nexwave"
                            employmentType="Fulltime (Contract)"
                            startDate="Feb 2018"
                            endDate="Feb 2019"
                            duration="1 yr"
                            url="https://www.ptnw.net"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/peto.png"}
                            title="Android Developer"
                            companyName="PT. PETO"
                            employmentType="Freelance"
                            startDate="Aug 2017"
                            endDate="Nov 2017"
                            duration="4 mos"
                            url="https://www.peto.pet"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/tukang.png"}
                            title="Android Developer"
                            companyName="PT. Tukang Teknologi Indonesia"
                            employmentType="Freelance"
                            startDate="Dec 2016"
                            endDate="Jan 2017"
                            duration="2 mos"
                            url="https://www.tukang.com"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/suzuki.png"}
                            title="Android & Backend Developer"
                            companyName="PT. Suzuki Indomobil Motor"
                            employmentType="Freelance"
                            startDate="Jun 2016"
                            endDate="Jul 2016"
                            duration="2 mos"
                            url="https://www.suzuki.co.id"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/itats.png"}
                            title="Web Developer"
                            companyName="Bridge to DIKTI Feeder by ITATS"
                            employmentType="Freelance"
                            startDate="Sep 2015"
                            endDate="Jan 2016"
                            duration="5 mos"
                            url="https://www.itats.ac.id"
                        />
                        <ExperienceList
                            icon={process.env.PUBLIC_URL + "/images/pdv.png"}
                            title="Web Developer"
                            companyName="Recruitment BPJS Kesehatan 2014 by PDV Consulting"
                            employmentType="Freelance"
                            startDate="Jun 2014"
                            endDate="Nov 2014"
                            duration="6 mos"
                            url="https://peopledevelopmentconsulting.com"
                        />
                    </div>
                </div>
            </div>

        </>
    );
}

export default Profile;