import React, { useState, useRef, useEffect } from "react";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import Alert from "../../uncatogorized/alert/Alert";
import CustomLoading from "../../uncatogorized/modal/Loading";
import axios from "axios";
import { API_BASE_URL } from "../../../config/Config"

const Contact = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSucceed, setIsSucceed] = useState(false);
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [messageError, setMessageError] = useState("");
    const [isOnInit, setIsOnInit] = useState(true);

    const nameRef = useRef();
    const emailRef = useRef();
    const subjectRef = useRef();
    const messageRef = useRef();

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleNameTypeChange = () => {
        setIsOnInit(false);
        if (nameRef.current.value.length > 0) {
            if (nameRef.current.value.length < 4) {
                setNameError("Your name is too short");
            } else {
                setNameError("");
            }
        } else {
            setNameError("Your name is required");
        }
    }

    const handleEmailTypeChange = () => {
        setIsOnInit(false);
        if (emailRef.current.value.length > 0) {
            if (validateEmail(emailRef.current.value)) {
                setEmailError("");
            } else {
                setEmailError("Invalid email format");
            }
        } else {
            setEmailError("Your email is required");
        }
    }

    const handleSubjectTypeChange = () => {
        setIsOnInit(false);
        if (subjectRef.current.value.length > 0) {
            if (subjectRef.current.value.length < 4) {
                setSubjectError("Your subject is too short");
            } else {
                setSubjectError("");
            }
        } else {
            setSubjectError("Your subject is required");
        }
    }

    const handleMessageTypeChange = () => {
        setIsOnInit(false);
        if (messageRef.current.value.length > 0) {
            if (messageRef.current.value.length < 4) {
                setMessageError("Your message is too short");
            } else {
                setMessageError("");
            }
        } else {
            setMessageError("Your message is required");
        }
    }

    const handleOnSubmitClick = () => {
        // validate
        handleNameTypeChange();
        handleEmailTypeChange();
        handleSubjectTypeChange();
        handleMessageTypeChange();

        if (isOnInit === false) {
            if (nameError === "" && emailError === "" && subjectError === "" && messageError === "") {
                // submit
                setIsSubmitted(true);
                axios.post(API_BASE_URL + "/send_mail", {
                    name: nameRef.current.value,
                    email: emailRef.current.value,
                    subject: subjectRef.current.value,
                    message: messageRef.current.value
                }).then((res) => {
                    setIsSubmitted(false);
                    setShowAlert(true);
                    setIsSucceed(true);
                    nameRef.current.value = "";
                    emailRef.current.value = "";
                    subjectRef.current.value = "";
                    messageRef.current.value = "";
                }).catch((err) => {
                    setIsSubmitted(false);
                    setShowAlert(true);
                    setIsSucceed(false);
                });
            }
        }
    }

    const onAlertClick = () => {
        setShowAlert(false)
    }

    return (
        <>
            <div className="min-h-screen sm:bg-black-bg">
                <CustomLoading
                    isOpen={isSubmitted}>
                </CustomLoading>
                <div className="md:container md:mx-auto mx-0 sm:mx-6 bg-white">
                    <Header />
                    <img src={process.env.PUBLIC_URL + "/images/bg-contact.png"} alt="" className="w-full h-[358px] sm:h-full" />
                    <div className="bg-white px-15 sm:px-36 -mt-80 flex flex-col items-center mb-10 sm:mx-0 mx-5">
                        <p className="text-2xl sm:text-5xl text-white font-bold text-center mb-8">Get In Touch</p>
                        <p className="text-sm sm:text-base text-white font-medium text-center px-10 sm:px-40 mb-8">
                            Don't hesitate to reach out. Together, let's showcase the excellence of your company to the world and contribute meaningfully to the betterment of society</p>
                        <div className="bg-white rounded-3xl w-full flex flex-col-reverse sm:flex-row p-4 sm:p-10 shadow-lg mb-32">
                            <div className="flex flex-col sm:basis-1/3 rounded-xl p-6 sm:p-10 bg-gradient-to-r from-blue-contact-left via-blue-contact-center to-blue-contact-right">
                                <p className="text-xl font-bold text-white mb-8">Contact Information</p>
                                <div className="flex flex-row pb-4">
                                    <img src={process.env.PUBLIC_URL + "/images/sms.png"} alt="" className="w-4 h-4 m-1" />
                                    <div className="flex flex-col pl-3">
                                        <p className="text-base text-white font-semibold">Email</p>
                                        <p className="text-base text-white font-normal sm:break-normal break-all">work.arifaradisa@gmail.com</p>
                                    </div>
                                </div>
                                <div className="flex flex-row pb-4">
                                    <img src={process.env.PUBLIC_URL + "/images/linkedin-small.png"} alt="" className="w-4 h-4 m-1" />
                                    <div className="flex flex-col pl-3">
                                        <p className="text-base text-white font-semibold">LinkedIn</p>
                                        <p className="text-base text-white font-normal sm:break-normal break-all">https://www.linkedin.com/in/arifaradisa/</p>
                                    </div>
                                </div>
                                <div className="flex flex-row pb-4">
                                    <img src={process.env.PUBLIC_URL + "/images/resume.png"} alt="" className="w-4 h-4 m-1" />
                                    <div className="flex flex-col pl-3">
                                        <p className="text-base text-white font-semibold pb-2">My Resume</p>
                                        <a
                                            href={process.env.PUBLIC_URL + "AriFaradisa-Resume.pdf"}
                                            download="AriFaradisa-Resume" target='_blank' rel="noreferrer"
                                            className="btn-default mt-4 lg:mt-0"
                                        >
                                            Download My Resume
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <form className="flex flex-col sm:basis-2/3 p-4 sm:p-10">
                                {showAlert ? (<Alert
                                    type={isSucceed === true ? "success" : "failed"}
                                    title={isSucceed === true ? "Message sent successfully" : "Something wrong"}
                                    message={isSucceed === true ? "Your message have successfully sent" : "Please check your input and try again"}
                                    onClick={onAlertClick}
                                />) : null}

                                <div className="flex flex-col sm:flex-row pb-8">
                                    <div className="flex flex-col flex-1 pr-5 sm:pb-0 pb-8">
                                        <p className="text-base font-normal pb-3">Your Name <span className="text-red-500">*</span></p>
                                        <input type="text" ref={nameRef} className="border rounded-md border-gray-300" onChange={handleNameTypeChange} placeholder="John Doe" />
                                        <p className="text-xs font-normal text-red-500 pt-2">{nameError}</p>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                        <p className="text-base font-normal pb-3">Your Email <span className="text-red-500">*</span></p>
                                        <input type="email" ref={emailRef} className="border rounded-md border-gray-300" onChange={handleEmailTypeChange} placeholder="johndoe@mail.com" />
                                        <p className="text-xs font-normal text-red-500 pt-2">{emailError}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col pb-8">
                                    <p className="text-base font-normal pb-3">Your Subject <span className="text-red-500">*</span></p>
                                    <input type="text" ref={subjectRef} className="border rounded-md border-gray-300" onChange={handleSubjectTypeChange} placeholder="I want to hire you quickly!" />
                                    <p className="text-xs font-normal text-red-500 pt-2">{subjectError}</p>
                                </div>
                                <div className="flex flex-col pb-8">
                                    <p className="text-base font-normal pb-3">Message <span className="text-red-500">*</span></p>
                                    <textarea ref={messageRef} onChange={handleMessageTypeChange} cols="30" rows="3" className="p-2 border rounded-md border-gray-300" placeholder="Write your message here"></textarea>
                                    <p className="text-xs font-normal text-red-500 pt-2">{messageError}</p>
                                </div>
                                <div className="flex sm:justify-start justify-center sm:pb-0 pb-4">
                                    <button type="button" onClick={handleOnSubmitClick} className="btn-default mt-0">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>)
}

export default Contact