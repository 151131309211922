import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import Profile from "../../common/profile/Profile";
import React, { useEffect } from "react";

const About = () => {
    const onCloseClick = () => {
        // do nothing
    }

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);


    return (
        <>
            <div className="min-h-screen sm:bg-black-bg">
                <div className="md:container md:mx-auto mx-0 sm:mx-6 bg-black-bg">
                    <Header hasBreadcrumb={true} paths={["About Me"]} />
                    <Profile onCloseClick={onCloseClick} />
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default About